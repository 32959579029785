import { FbDocument, getKeyValueConstructor } from './FbDocument';

export default class ServiceInfo extends FbDocument {
    /** Always the same as id, used by security rules */
    name = '';
    duration = 0;
    currency = '';
    price = 0;
    seasonPrices: SeasonPrice[] = [];
    info: { [locale: string]: Info } = {};
    hidden: boolean = false;

    getConstructorFor(member: string) {
        if (member === 'info') {
            return getKeyValueConstructor(Info);
        }
        else if (member === 'seasonPrices') {
            return SeasonPrice;
        }
        return super.getConstructorFor(member);
    }
}

class Info {
    name = '';
    description = '';
}

export class SeasonPrice {
    from: DayMonth;
    to: DayMonth;
    price = 0;
}

class DayMonth {
    day = 1;
    month = 1;
}