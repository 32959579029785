import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { BookingsComponent } from './bookings/bookings.component';
import { AdminLoginComponent } from './admin-login.component';
import { AdminDashboardComponent } from './admin-dashboard.component';
import { AdminRouterComponent } from './admin-router.component';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatSortModule } from '@angular/material/sort';
import { MatDividerModule } from '@angular/material/divider';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { BookingDetailsComponent } from './bookings/booking-details.component';

@NgModule({
  declarations: [AdminRouterComponent, AdminLoginComponent, BookingsComponent, AdminDashboardComponent, BookingDetailsComponent],
  imports: [
    SharedModule,
    MatTableModule,
    MatSortModule,
    MatDividerModule,
    MatFormFieldModule,
    MatSelectModule
  ],
  exports: [
    AdminRouterComponent
  ]
})
export class AdminModule { }
