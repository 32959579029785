/*
 * Avoid providing services in shared modules.
 *
 * Do import all modules required by the assets in the SharedModule;
 * for example, CommonModule and FormsModule.
 *
 * Do declare all components, directives, and pipes in the SharedModule.
 * Do export all symbols from the SharedModule that other feature modules need to use.
 *
 */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoRootModule } from '../transloco-root.module';
import { NgxJsonLdComponent } from './ngx-json-ld.component';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { SnackMessageComponent } from './snack-message.component';
import { FormattedTextComponent } from './formatted-text.component';
import { FirestorageImageDirective } from './firestorage-image.directive';
import { ScrollToDirective } from './scroll-to-top.directive';

@NgModule({
    declarations: [SnackMessageComponent, FormattedTextComponent, FirestorageImageDirective, ScrollToDirective, NgxJsonLdComponent],
    imports: [
        CommonModule,
        RouterModule,
        BrowserAnimationsModule,
        FontAwesomeModule,
        HttpClientModule,
        TranslocoRootModule,
        ReactiveFormsModule,
        // Internal
        MatSnackBarModule,
        MatButtonModule
    ],
    exports: [
        CommonModule,
        RouterModule,
        BrowserAnimationsModule,
        FontAwesomeModule,
        HttpClientModule,
        TranslocoRootModule,
        ReactiveFormsModule,
        MatButtonModule,
        // Locals
        SnackMessageComponent,
        FormattedTextComponent,
        FirestorageImageDirective,
        ScrollToDirective,
        NgxJsonLdComponent
    ],
    providers: [],
    bootstrap: []
})
export class SharedModule { }
